import React from "react";
import { InfoEmail } from "./Emails";
import { SocialIcons } from "./SocialIcons";
import Logo from "../svgs/Logo.svg";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__row">
          <Logo className="footer__logo" />
          <div className="footer__row">
            <SocialIcons />
            <div>
              <p>Contact us</p>
              <InfoEmail />
            </div>
          </div>
        </div>
        <div className="footer__row footer__info">
          <span>© Out Out Events {new Date().getFullYear()}</span>
          <div className="footer__terms">
            <a
              href="https://fixr.co/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <span>Privacy Policy</span>
            </a>{" "}
            <span>•</span>{" "}
            <a
              href="https://fixr.co/legal/organiser-terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              <span>Terms & Conditions</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
