import React from "react";

import IconFacebook from "../svgs/IconFacebook.svg";
import IconInstagram from "../svgs/IconInstagram.svg";
import IconTiktok from "../svgs/IconTiktok.svg";
import IconWhatsApp from "../svgs/IconWhatsApp.svg";

export function SocialIcons({}) {
  return (
    <div className={`social-icons`}>
      <a
        href="https://www.facebook.com/letsgooutout"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href="https://instagram.com/out_out_events_"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInstagram />
      </a>
      <a
        href="https://tiktok.com/lets_go_out_out"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconTiktok />
      </a>
      <a
        href="https://wa.me/+447398513026"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconWhatsApp />
      </a>
    </div>
  );
}
