import React from "react";
import { Link } from "gatsby";
import { SocialIcons } from "./SocialIcons";
import Logo from "../svgs/Logo.svg";

export function Header() {
  return (
    <header className="header">
      <div className="header__content">
        <Link to="/">
          <Logo className="header__logo" />
        </Link>
        <div className="header__nav-wrapper">
          <nav className="header__nav">
            <SocialIcons />
          </nav>
        </div>
      </div>
    </header>
  );
}
