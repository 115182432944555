import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

export default function Layout({ children, path, ...rest }) {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
}
